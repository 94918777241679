<template>
  <div class="card-my-offered">
    <card-info
      :item="offered"
      :hide-operator="true"
    />
    <div
      v-if="statusIsNotSold"
      class="card-actions"
    >
      <div class="mb-8 d-flex justify-space-between">
        <div>
          <div class="action-title">
            {{ $t('components.card_my_offers.total_value') }}
          </div>
          <div class="total-value">
            {{ formatMoney(totalValue) }}
          </div>
        </div>

        <div>
          <v-chip
            class="ma-2 font-weight-bold"
            :color="offered.status"
            text-color="white"
          >
            {{ offered.status_text }}
          </v-chip>
        </div>
      </div>

      <div class="action-form d-flex justify-space-between mb-8">
        <div class="mr-4">
          <div class="text-info">
            {{ $t('components.card_my_offers.unit_value') }}
          </div>
          <div class="d-flex justify-center">
            <input
              v-model="unitValue"
              v-mask="['##', '#,##', '##,##', '###,##']"
              type="text"
              class="field-offered"
              placeholder="..."
              :disabled="!creditOfferedActive"
            >
          </div>
        </div>
      </div>
      <div
        class="button-actions"
      >
        <rectoplus-button
          class="save-button"
          :text="$t('components.card_my_offers.buttons.save')"
          :disabled="validateBeforeSave || !creditOfferedActive"
          @click="emitUpdate"
        />
        <rectoplus-button
          class="delete-button"
          :text="$t('components.card_my_offers.buttons.delete')"
          color="red"
          :disabled="!creditOfferedActive"
          @click="emitDelete"
        />
      </div>
    </div>
    <div
      v-else
      class="card-info-sold"
    >
      <div>
        <v-chip
          class="ma-2 font-weight-bold"
          :color="offered.status"
          text-color="white"
        >
          {{ offered.status_text }}
        </v-chip>
      </div>
      <div>
        <div class="mt-2">
          {{ $t('components.card_my_offers.total_value') }}
        </div>
        <div class="total-value">
          {{ formatMoney(totalValue) }}
        </div>
      </div>

      <div>
        <div class="mr-4">
          <div>
            {{ $t('components.card_my_offers.unit_value') }}
          </div>
          <div class="d-flex justify-center">
            <input
              v-model="unitValue"
              v-mask="['##', '#,##', '##,##', '###,##']"
              type="text"
              class="unit-value"
              placeholder="..."
              :disabled="!creditOfferedActive"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatMoney from '@/mixins/formatMoney';
import formatMask from '@/mixins/formatMask';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import CardInfo from '@/components/cards/offered/CardInfo.vue';

export default {
  name: 'CardMyOffered',
  components: { RectoplusButton, CardInfo },
  mixins: [formatMoney, formatMask],
  props: {
    offered: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      unitValue: '',
    };
  },
  computed: {
    validateBeforeSave() {
      return this.validatePresence || this.validateValue || !this.isEdit;
    },

    validatePresence() {
      return !this.unitValue;
    },

    validateValue() {
      return this.unitValue <= 0;
    },

    formatUnitValue() {
      return this.decimalMaskToNumber(this.unitValue);
    },

    isEdit() {
      return this.totalValue !== Number(this.offered.total_value);
    },

    creditOfferedActive() {
      return this.offered.status === 'active';
    },

    totalValue() {
      return Number(this.offered.total_value);
    },

    statusIsNotSold() {
      return this.offered.status !== 'sold';
    },

    hasRelationOffers() {
      return this.offered.related_offers.length > 0;
    },
  },
  watch: {
    offered(value) {
      this.unitValue = this.formatMoney(Number(value.unit_value));
    },
  },
  mounted() {
    this.unitValue = this.formatMoney(Number(this.offered.unit_value));
  },
  methods: {
    mountMaterial(material) {
      if (material === 'Papel') return 'paper';
      if (material === 'Plástico') return 'plastic';
      if (material === 'Vidro') return 'glass';
      return 'metal';
    },

    emitDelete() {
      if (this.hasRelationOffers) {
        return this.$emit('openConfirmDialog', this.offered.related_offers, this.offered.id);
      }

      return this.$emit('delete', this.offered.id);
    },

    emitUpdate() {
      this.$emit('update', this.offered.id, this.formatUnitValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-my-offered {
  display: flex;
  justify-content: space-between;
  background: $card_offer;
  border-radius: 10px;
  padding: 20px;
  box-shadow: $box_shadow_my_offereds;
  margin-bottom: 20px;

  .card-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20em;

    .action-title {
      font-size: 1em;
      color: $color_gray;
    }

    .total-value {
      font-size: 1em;
      color: $color_dark;
      font-weight: bold;
    }

    .button-actions {
      .save-button {
        width: 100%;
        height: 56px;
        margin-bottom: 20px;
      }

      .delete-button {
        width: 100%;
        height: 56px;
      }
    }

    .action-form {
      .field-offered {
        background: $dashboard_background;
        width: 100%;
        max-width: 150px;
        border-radius: 10px;
        padding: 4px;
        text-align: center;
        outline: none;
      }

      .text-info{
        margin-bottom: 4px;
        font-size: 0.875em;
        color: $color_gray;
      }
    }
  }

  .card-info-sold {
    display: flex;
    flex-direction: column;
    align-content: flex-end;

    .total-value {
      font-weight: bold;
      padding: 10px 0;
    }

    .unit-value {
      color: $color_dark;
      font-weight: bold;
      padding: 10px 0;
    }
  }
}
</style>
