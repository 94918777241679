<template>
  <div class="my-offereds">
    <div class="title-my-offereds">
      <back-button
        class="back-button"
        :text="$t('views.my_offereds.back')"
        @click="goToHome()"
      />
      <span class="page">{{ $t('views.my_offereds.title') }}</span>
    </div>
    <div class="card-my-offereds">
      <div v-if="hasOffereds">
        <div class="head-my-offereds">
          <filter-my-offereds
            @change="fetchByFilters"
          />
        </div>

        <card-my-offered
          v-for="(item, index) in myOffereds.data"
          :key="index"
          :offered="item"
          @delete="deleteOffered"
          @update="updateOffered"
          @openConfirmDialog="openConfirmDialog"
        />

        <rectoplus-pagination
          :size="pagination.size"
          :page="pagination.page"
          @changed="changePage"
        />
      </div>
      <div
        v-else
        class="no-offereds"
      >
        <span> {{ $t('views.my_offereds.no_offereds') }} </span>
      </div>
    </div>

    <delete-related-offers-dialog
      :id="id"
      :related-offers="relatedOffers"
      @confirm="deleteOffered"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CardMyOffered from '@/components/cards/offered/CardMyOffered.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import DeleteRelatedOffersDialog from '@/components/dialogs/DeleteRelatedOffersDialog.vue';
import RectoplusPagination from '@/components/pagination/RectoplusPagination.vue';
import FilterMyOffereds from '@/components/headers/offered/FilterMyOffereds.vue';

export default {
  name: 'MyOffereds',
  components: {
    CardMyOffered,
    BackButton,
    DeleteRelatedOffersDialog,
    RectoplusPagination,
    FilterMyOffereds,
  },
  data: () => ({
    relatedOffers: [],
    id: null,
    filters: {
      order_by: 'recent',
    },
  }),
  computed: {
    ...mapGetters({
      myOffereds: 'getMyOffereds',
      business: 'getBusiness',
    }),
    hasOffereds() {
      return this.myOffereds.data && this.myOffereds.data.length > 0;
    },
    pagination() {
      return {
        size: this.myOffereds.pagination_settings?.pages,
        page: this.myOffereds.pagination_settings?.page,
      };
    },
  },
  mounted() {
    this.changePage(1);
  },
  methods: {
    ...mapActions([
      'fetchMyOffereds',
      'initLoading',
      'deleteMyCreditOffered',
      'updateMyCreditOffered',
      'openRelatedOffersDialog',
    ]),
    openConfirmDialog(relatedOffers, id) {
      this.relatedOffers = relatedOffers;
      this.id = id;

      this.openRelatedOffersDialog();
    },

    deleteOffered(id) {
      this.relatedOffers = [];
      this.id = null;

      this.initLoading();
      this.deleteMyCreditOffered({
        business_id: this.business.id,
        id,
      });
    },

    updateOffered(id, unitValue) {
      this.initLoading();
      this.updateMyCreditOffered({
        business_id: this.business.id,
        id,
        payload: {
          unit_value: unitValue,
        },
      });
    },

    changePage(page) {
      this.initLoading();
      this.fetchMyOffereds({
        business_id: this.business.id,
        payload: {
          ...this.filters,
          page,
        },
      });
    },

    fetchByFilters(filters) {
      this.filters = filters;
      this.changePage(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.my-offereds {
  background: $dashboard_background;
  min-height: 100vh;
  padding: 2em 2em 2em 16em;
  display: flex;
  flex-direction: column;

  .title-my-offereds {
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    position: relative;

    .page {
      font-size: 1.5em;
      font-weight: bold;
      margin: auto;
    }

    .back-button {
      position: absolute;
    }
  }

  .card-my-offereds {
    width: 100%;
    height: 100%;
    background: $color_white;
    border-radius: 10px;
    padding: 44px 32px;
    margin-bottom: 20px;

    .head-my-offereds {
      margin-bottom: 48px;
    }

    .no-offereds {
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      color: $color_gray;
    }
  }
}
</style>
