<template>
  <v-pagination
    :value="page"
    :length="size"
    :total-visible="7"
    v-bind="computedLenght"
    class="pagination"
    color="primary"
    @input="emitChange"
  />
</template>

<script>

export default {
  name: 'RectoplusPagination',
  props: {
    size: {
      default: 1,
      type: Number,
    },
    page: {
      default: 1,
      type: Number,
    },
  },
  computed: {
    computedLenght() {
      return { totalVisible: 4 };
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    emitChange(page) {
      this.$emit('changed', page);
      this.scrollToTop();
    },
  },
};
</script>
