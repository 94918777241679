<template>
  <div class="filter-card-my-offered">
    <div class="filter-content">
      <div class="name-filter">
        {{ $t('components.filter_my_offereds.title_filter_order_by') }}
      </div>
      <div class="content-chip">
        <div
          v-for="(value, index) in orderByOptions"
          :key="index"
          :class="['chip-filter', orderBySelected(value)]"
          @click="selectOrderBy(value)"
        >
          {{ $t(`components.filter_my_offereds.order_by_options.${value}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterMyOffereds',
  data() {
    return {
      orderByOptions: ['recent', 'old'],
      filters: {
        order_by: 'recent',
      },
    };
  },
  methods: {
    selectOrderBy(value) {
      this.filters = { ...this.filters, order_by: value };
      this.$emit('change', this.filters);
    },

    orderBySelected(value) {
      return this.filters.order_by === value ? 'chip-selected' : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-card-my-offered {
  display: flex;
  justify-content: space-between;

  .filter-content {
    margin-right: 62px;

    .name-filter {
      text-align: start;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .content-chip {
      display: flex;
      justify-content: space-between;

      .chip-filter {
        background-color: transparent;
        border: $color_text_chip_filter solid 1px;
        border-radius: 24px;
        height: 30px;
        padding: 0 16px;
        font-size: 0.875em;
        font-weight: 600;
        display: flex;
        margin-right: 12px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color_text_chip_filter;

        &.chip-selected {
          background-color: $color_primary;
          border-color: $color_primary;
          color: white;
        }
      }
    }
  }
}
</style>
