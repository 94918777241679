<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-top-transition"
    max-width="600"
  >
    <div class="card-dialog">
      <button
        class="button-close"
        @click="closeDialog()"
      >
        <v-icon color="primary">
          mdi-close
        </v-icon>
      </button>

      <h1 class="title">
        {{ $t('components.delete_releted_offers_dialog.title') }}
      </h1>

      <main class="main-offer">
        <p> {{ $t('components.delete_releted_offers_dialog.description.one') }} </p>

        <div
          v-for="(offer, index) in relatedOffers"
          :key="index"
          class="content-offer"
        >
          <component :is="getIconByMaterialType(mountMaterial(offer.material_type), false)" />

          <div class="info-offer">
            <p class="text">
              {{ formatMoney(offer.unit_value) }}
            </p>
            <p class="text">
              {{ formatMoney(offer.total_value) }}
            </p>
          </div>
        </div>

        <p> {{ $t('components.delete_releted_offers_dialog.description.two') }} </p>
      </main>

      <div class="content-buttons">
        <button
          class="button"
          @click="confirm()"
        >
          {{ $t('components.delete_releted_offers_dialog.confirm') }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import getIconsByMaterialType from '@/mixins/getIconsOfMaterialType';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'RelatedOffersDialog',
  mixins: [getIconsByMaterialType, formatMoney],

  props: {
    relatedOffers: {
      type: Array,
      default: () => ([]),
    },
    id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters([
      'getRelatedOffersDialog',
      'getMandatoryOffers',
    ]),
  },

  watch: {
    getRelatedOffersDialog(value) {
      this.dialog = value;
    },
  },

  methods: {
    ...mapActions(['closeRelatedOffersDialog']),

    closeDialog() {
      this.closeRelatedOffersDialog();
    },

    confirm() {
      this.closeDialog();

      this.$emit('confirm', this.id);
    },

    mountMaterial(material) {
      if (material === 'Papel') return 'paper';
      if (material === 'Plástico') return 'plastic';
      if (material === 'Vidro') return 'glass';
      return 'metal';
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  padding: 40px;
  border-radius: 10px;
  background: $dialog_bank_account_background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .button-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .main-offer {
    .content-offer {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 20px 0;

      .info-offer {
        .text {
          margin: 0;

          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .content-buttons {
    .button {
      width: 100%;
      background-color: transparent;
      border: 2px solid $color_primary;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      text-decoration: none;
      color: $color_primary;
      font-weight: bold;
      display: block;
    }
  }
}
</style>
